$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });


  if($('body').hasClass('home')){
	  $('.carousel').on('init', function(event, slick, direction){
	  	switchBackgroundImgs();
	  	$('.slick-slide .img').height($(window).height());
		}).slick();

		$(window).resize(function() {
			$('.slick-slide .img').height($(window).height());
			switchBackgroundImgs();
		});
  }


  function switchBackgroundImgs(){
  	if($(window).width() < 500){
				if(!$('body').hasClass('has-mobile-alt')){
					$('.slick-slide .img').each(function(){
						$(this).css({'background-image':'url('+ $(this).attr("data-mobile-alt")+')'})
					})
					$('body').addClass('has-mobile-alt');
				}
			}else{
				if($('body').hasClass('has-mobile-alt')){
					$('.slick-slide .img').each(function(){
						$(this).css({'background-image':'url('+ $(this).attr("data-reg-alt")+')'})
					})
					$('body').removeClass('has-mobile-alt');
				}
			}
  }

});

